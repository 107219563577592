export const initializeMandatoryParameters = (data: any, parameters: { [s: string]: any }) => {
    for (const index in parameters) {
        const value = data[index];
        if (value === undefined) {
            throw new Error(`Missing mandatory parameter ${index}`);
        }

        const type = typeof parameters[index];
        if (type !== typeof value) {
            throw new Error(`Type of ${index} must be ${typeof parameters[index]}`);
        }

        if (type === 'string' && value === '') {
            throw new Error(`${index} can't be empty string`);
        }

        parameters[index] = value;
    }
};