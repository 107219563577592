import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        logout: 'Logout',
        displayname: 'displayname',
        sharename: 'sharename',
        profilename: 'profilename',
        owner: 'owner',
        type: 'Device type',
        logginHeading: 'Log in to see your obsoleted groups.',
        loginWithGoogleAccount: 'Login with Google account',
        loggingIn: 'Logging in...',
        app: 'App',
        mobileClient: 'Mobile client'
    },
    fi: {
        logout: 'Kirjaudu ulos',
        displayname: 'näyttönimi',
        sharename: 'jakonimi',
        profilename: 'profiilin nimi',
        owner: 'omistaja',
        type: 'Laitetyyppi',
        logginHeading: 'Kirjaudu sisään nähdäksesi vanhentuneet ryhmäsi.',
        loginWithGoogleAccount: 'Kirjaudu Google-tilillä',
        loggingIn: 'Kirjautuu...',
        app: 'Sovellus',
        mobileClient: 'Puhelinsovellus'
    },
    sv: {
        logout: 'Logga ut',
        displayname: 'visningsnamn',
        sharename: 'delnamn',
        profilename: 'profilnamn',
        owner: 'ägare',
        type: 'Enhetstyp',
        logginHeading: 'Logga in för att se dina föråldrade grupper.',
        loginWithGoogleAccount: 'Logga in med Google-konto',
        loggingIn: 'Loggar in...',
        mobileClient: 'Mobil klient',
    },
});