import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';
import GoogleLogin from './components/GoogleLogin';
import ProtectedRoute from './components/ProtectedRoute';
import { TargetList } from './components/TargetList';
import { Container } from 'react-bootstrap';

const App = () => {
  return (
    <Container>
      <AuthContextProvider>
        <Header />
        <Routes>
          <Route path='/login' element={<GoogleLogin />} />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <TargetList />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </Container>
  );
}

export default App;