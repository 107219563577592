import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const trabbentFirebaseConfig = {
    apiKey: "AIzaSyARRGIiz4Vdo3X8pV9saKfMoQpB_KbNQ2s",
    authDomain: "share.tracker.fi",
    databaseURL: "https://trabbent-c71d6.firebaseio.com",
    projectId: "trabbent-c71d6",
    storageBucket: "trabbent-c71d6.appspot.com",
    messagingSenderId: "679054175059",
    appId: "1:679054175059:web:0ceba66aeb2ee57c1dcfa6",
    measurementId: "G-87NRZ98MY0"
};

const trabbentApp = initializeApp(trabbentFirebaseConfig, 'Trabbent');

export const trabbentFirestore = getFirestore(trabbentApp);

export const trabbentAuth = getAuth(trabbentApp);