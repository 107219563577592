import { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithPopup,
  signOut as _signOut,
  onAuthStateChanged,
  User,
  GoogleAuthProvider,
  UserCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { oagAuth, oagFirestore } from '../services/oag-firebase-config';
import { getJWT } from '../services/quinze';
import { doc, getDoc } from 'firebase/firestore';
import { trabbentAuth } from '../services/trabbent-firebase-config';
import { getCustomFirebaseToken } from '../services/trabbent-tracker';
import { initializeMandatoryParameters } from '../common';

export interface LoginCredentials {
  user: User;
  publishname: string;
  liveID: number;
  jwt: string;
}

interface UserContextType {
  loginCredentials: LoginCredentials | null;
  signOut: () => Promise<void>;
  signIn: () => Promise<UserCredential>;
}

const signIn = async () => {
  const credential = await signInWithPopup(oagAuth, provider);
  return credential;
}

const signOut = () => {
  return _signOut(oagAuth);
}

const UserContext = createContext<UserContextType>({ loginCredentials: null, signOut, signIn });

const provider = new GoogleAuthProvider();

export const AuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const [credentials, setCredentials] = useState<LoginCredentials | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(oagAuth, async (currentUser) => {
      if (!currentUser) {
        setCredentials(null);
        return;
      }
      const db = oagFirestore;
      const docRef = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(docRef);

      const data = {
        publishname: docSnap.get('livePublishname'),
        licenseKey: docSnap.get('trackerLicense'),
        liveID: docSnap.get('liveID'),
      };
      const p = {
        publishname: '',
        licenseKey: '',
        liveID: -1,
      };
      initializeMandatoryParameters(data, p);

      const [jwt] = await Promise.all([
        getJWT(p.publishname!, p.licenseKey!),
        getCustomFirebaseToken(p.liveID).then(token => signInWithCustomToken(trabbentAuth, token))
      ]);
      setCredentials({
        user: currentUser,
        publishname: p.publishname,
        liveID: p.liveID,
        jwt
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ loginCredentials: credentials, signOut, signIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};