import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Accordion, ListGroup, ListGroupItem } from "react-bootstrap";

import { UserAuth } from "../context/AuthContext";
import { strings } from "../localizations";
import { isMC, isReindeer, LiveService, Member } from "../services/live-service";
import { trabbentFirestore } from "../services/trabbent-firebase-config";

type Groups = { [key: number]: Group };

type Group = {
    displayName: string;
    members: { [key: number]: Member };
}

export const TargetList = () => {
    const [groups, _setGroups] = useState<Groups | null>(null);
    const { loginCredentials } = UserAuth();

    const setGroups = (groups: Groups) => {
        _setGroups({ ...groups });
    }

    useEffect(() => {
        if (!loginCredentials) {
            throw new Error('Missing login credentials');
        }
        const toUnsub: Unsubscribe[] = [];
        const liveServce = new LiveService(loginCredentials.jwt);
        liveServce.getGroups().then(data => {
            const tdsPerGroup: Groups = {};
            for (const g of data.myGroups) {
                tdsPerGroup[g.id] = {
                    displayName: g.displayName,
                    members: {}
                };
                for (const m of g.members) {
                    tdsPerGroup[g.id].members[m.liveId] = m;
                    toUnsub.push(onSnapshot(doc(trabbentFirestore, `devices/${m.liveId}`), async (dev) => {
                        tdsPerGroup[g.id].members[m.liveId].sharename = dev.get('shareName');
                        tdsPerGroup[g.id].members[m.liveId].imei = dev.get('imei');
                        setGroups(tdsPerGroup);
                    }));

                    if (isMC(m.type)) {
                        continue;
                    }

                    toUnsub.push(onSnapshot(doc(trabbentFirestore, `targetAttributes/${m.liveId}`), async (devAttr) => {
                        const ownerLiveID: string | undefined = devAttr.get('owner');
                        tdsPerGroup[g.id].members[m.liveId].ownerID = ownerLiveID;
                        if (!ownerLiveID) {
                            return;
                        }

                        toUnsub.push(onSnapshot(doc(trabbentFirestore, `targetAttributes/${ownerLiveID}`), async (ownerAttr) => {
                            tdsPerGroup[g.id].members[m.liveId].ownerDisplayname = ownerAttr.get('displayName');
                            setGroups(tdsPerGroup);
                        }));

                        const carrierProfileID: string | undefined = devAttr.get('carrierProfileID');
                        if (carrierProfileID) {
                            toUnsub.push(onSnapshot(doc(trabbentFirestore, `carrierProfiles/${carrierProfileID}`), async (carrierProfile) => {
                                tdsPerGroup[g.id].members[m.liveId].carrierProfileName = carrierProfile.get('name');
                                setGroups(tdsPerGroup);
                            }));
                        }
                    }));
                }
            }

            setGroups(tdsPerGroup);
        });
        return () => {
            for (const unsub of toUnsub) {
                unsub();
            }
        }
    }, [loginCredentials]);

    const members = (group: Group) => {
        const sort = (items: Member[]) => items.sort((a, b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
        const all = Object.values(group.members);
        const handle = (members: Member[], test: (member: Member) => boolean) => sort(all.filter(test));
        const result = [
            ...handle(all, (m) => !isMC(m.type)),
            ...handle(all, (m) => isMC(m.type))
        ];
        return result;
    }

    return groups ? <Accordion defaultActiveKey="0">
        {Object.entries(groups).map(([groupID, group]) =>
            <Accordion.Item key={groupID} eventKey={groupID}>
                <Accordion.Header>{group.displayName}</Accordion.Header>
                <Accordion.Body>
                    <ListGroup>
                        {members(group).map((m) => {
                            const key = `${m.displayName}${m.sharename}${m.carrierProfileName}${m.ownerDisplayname}${m.type}`;
                            const th6 = 'Tracker Hunter 6';
                            const t7 = `Tracker ${strings.app}`;
                            return <ListGroupItem key={key}>
                                <p>{th6} {strings.displayname}: {m.displayName}</p>
                                {m.sharename && <p>{th6} {strings.sharename}: {m.sharename}</p>}
                                {m.carrierProfileName && <p>{t7} {strings.profilename}: {m.carrierProfileName}</p>}
                                {m.ownerDisplayname && <p>{t7} {strings.owner}: {m.ownerDisplayname}</p>}
                                {m.type && <p>Type: {isMC(m.type) ? strings.mobileClient : m.type}</p>}
                                {isReindeer(m.type) && m.imei && !m.ownerID && <p>Imei: {m.imei}</p>}
                            </ListGroupItem>;
                        }
                        )}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>)}
    </Accordion> : <p>Missing groups</p>;
}