import { UserAuth } from "../context/AuthContext";
import { strings } from "../localizations";

const Header = () => {
    const { loginCredentials, signOut } = UserAuth();

    return (
        loginCredentials ?
            <nav className='navbar navbar-expand-lg navbar-light bg-light mb-3'>
                <button className='btn btn-primary' onClick={signOut}>{strings.logout}</button>
            </nav> :
            <></>
    )
}

export default Header;