import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const oagFirebaseConfig = {
    apiKey: "AIzaSyC6sVDw8QBQQhHgH_04Vsqf3VVV7eHPtA8",
    authDomain: "share.tracker.fi",
    projectId: "oag-production",
    storageBucket: "oag-production.appspot.com",
    messagingSenderId: "1008485154426",
    appId: "1:1008485154426:web:07efc814aaaa5e5f0585c3",
    measurementId: "G-782E634SKT"
};

const oagApp = initializeApp(oagFirebaseConfig, 'OAG');

export const oagFirestore = getFirestore(oagApp);

export const oagAuth = getAuth(oagApp);