import React from 'react';
import { Navigate } from 'react-router-dom';

import { UserAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { loginCredentials } = UserAuth();

  if (!loginCredentials) {
    return <Navigate to='/login' />;
  }

  return <>{children}</>
};

export default ProtectedRoute;
