import axios from 'axios';

const _axios = axios.create({
    baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
        'http://localhost:5001/trabbent/' :
        'https://trabbent.tracker.fi/synchronization/getFirebaseAuthTokenV2/',
});

export const getCustomFirebaseToken = async (liveID: number): Promise<string> => {
    const response = await _axios.get('', { params: { uid: liveID } });
    return response.data;
};