import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const softwareName = 'OldGroupsSite';

const clientID = (() => {
    const key = 'clientID';
    let id = localStorage.getItem(key);
    if (!id) {
        id = uuidv4();
        localStorage.setItem(key, id);
    }
    return id;
})();
const clientDescription = window.navigator.userAgent;

const _axios = axios.create({
    baseURL: 'https://quinze.tracker.fi/api/v1/app/',
    headers: {
        'Content-Type': 'application/json'
    }
});

const commonRequestData = {
    functions: ['func-tb-app'],
    deviceId: clientID,
    description: clientDescription,
}

const commonQuinzeRequest = async (action: string, data: { [key: string]: any; }, expectSuccess = true) => {
    const requestData = JSON.stringify({
        ...commonRequestData,
        ...data
    });

    const response = await _axios.post(action, requestData);

    if (expectSuccess && response.data.status !== 'SUCCESS') {
        const msg = `${action} request wasn't success. ${JSON.stringify(response.data)}`;
        console.error(msg);
        throw new Error(msg);
    }

    return response.data;
}

const checkOrActivateLicense = async (
    publishname: string,
    licenseKey: string,
    activate: boolean,
    data: { [key: string]: any; } = {}
) => {
    const requestData = await commonQuinzeRequest(`${activate ? 'activate' : 'check'}-license`, {
        licenseKey,
        ...data,
        switchLicenses: true,
        tokens: [
            {
                type: 'live',
                publishname,
                software: softwareName
            }
        ],
    }, false);
    return requestData;
}

export const getJWT = async (publishname: string, licenseKey: string): Promise<string> => {
    let checkLicenseData = await checkOrActivateLicense(publishname, licenseKey, false);
    if (checkLicenseData.status === 'RESERVED') {
        const transferFrom = checkLicenseData.activations.devices.list[0].id;
        checkLicenseData = await checkOrActivateLicense(publishname, licenseKey, true, { transferFrom: transferFrom });
    }

    if (checkLicenseData.tokens.live.status !== 'SUCCESS') {
        throw Error(`Failed to get live token because: ${checkLicenseData?.tokens?.live?.message}`);
    }

    const jwt = checkLicenseData.tokens.live.jwt;

    return jwt;
}