import axios, { AxiosInstance } from 'axios';
import deviceTypes from './devicetypes.json';

export class LiveService {
  private _axios: AxiosInstance;

  constructor(jwt: string) {
    this._axios = axios.create({
      baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
        'http://localhost:5001/live' :
        'https://live5.tracker.fi/groups/v5.2/api.aspx',
      headers: {
        jwt: jwt
      }
    });
  }

  async getGroups(): Promise<MyGroupsData> {
    const response = await this._axios.get('', { params: { action: 'mygroups' } });
    return response.data as MyGroupsData;
  }
}

export interface MyGroupsResponse {
  data: MyGroupsData;
}

export interface MyGroupsData {
  myGroups: MyGroup[];
}

export interface MyGroup {
  displayName: string;
  id: number;
  name: string;
  description: string;
  created: string;
  password: string;
  freeInvitation: boolean;
  rootId: null;
  members: Member[];
}

export interface Member {
  displayName: string;
  liveId: number;
  phoneNumber: null | string;
  type: string;
  access: number;
  isAdmin: boolean;
  sharename: string | undefined;
  imei: string | undefined;
  carrierProfileName: string | undefined;
  ownerID: string | undefined;
  ownerDisplayname: string | undefined;
}

export const isMC = (type: string) => {
  const mcNames = deviceTypes.deviceTypes.filter(d => d.species === 'People').map(d => d.name);
  return ['APITESTER', ...mcNames].includes(type);
}

export const isReindeer = (type: string) => {
  const reindeerNames = deviceTypes.deviceTypes.filter(d => d.species === 'Reindeer').map(d => d.name);
  return reindeerNames.includes(type);
}