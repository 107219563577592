import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { strings } from '../localizations';

const GoogleLogin = () => {
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const { loginCredentials, signIn } = UserAuth();

    if (loginCredentials) {
        return <Navigate to='/' />;
    }

    return (
        isLoggingIn ?
            <p>{strings.loggingIn}</p> :
            <div className="login-area card mx-auto mt-5">
                <div className="card-body p5">
                    <h5 className="text-center mb-4">{strings.logginHeading}</h5>
                    <button
                        type="button"
                        className="btn btn-primary text-white w-100"
                        onClick={async () => {
                            setIsLoggingIn(true);
                            await signIn();
                            setIsLoggingIn(false);
                        }}>
                        {strings.loginWithGoogleAccount}
                    </button>
                </div>
            </div>
    )
}

export default GoogleLogin